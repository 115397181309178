/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

input:focus{
    --tw-ring-color :none !important;
    --tw-ring-shadow:none !important;
}
textarea:focus{
    --tw-ring-color :none !important;
    --tw-ring-shadow:none !important;
}
[multiple]:focus{
    --tw-ring-color :none !important;
    --tw-ring-shadow:none !important;
    border: none !important; 
}
[multiple]{
    
    border: none !important; 
}
ngx-material-timepicker-container{
    z-index: 1000;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
//  .cdk-overlay-pane {
//     width: auto !important;
// }
.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    // width: auto !important;
}
.mat-mdc-select-disabled{
    color: black !important;
}

.dynamicTerms table{
    margin-top: 15px;
    margin-bottom: 15px;
}
  .dynamicTerms td{
    border: 1px solid black !important;
    padding: 3px;
}
  .dynamicTerms p{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}